
.ant-table-bordered .ant-table-thead > tr > th, body .ant-table-wrapper tr td {
  border: 1px solid #e8e8e8 !important;
}
.ant-table-bordered .ant-table-thead > tr > .border-left-thick, .ant-table-bordered .ant-table-tbody > tr > .border-left-thick {
  border-left: 2.5px solid #e8e8e8 !important;
}
.wrongCell {
  background-color: #FED1D1 !important;
}
